import { Stack, Text, Button } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineAlert } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

function NoAccessPage() {

  const navigate = useNavigate()

  return (
    <Stack minH="50vh" alignItems={'center'} justifyContent="center">
      <AiOutlineAlert color="gray" size={100} />
      <Text textAlign="center" color={'gray.500'} fontSize="2xl" fontWeight={'bold'}>Warning!</Text>
      <Text fontWeight={500} fontSize="lg" textAlign="center" color={'gray.500'}>Im sorry, but based on your role access, you cannot access this page.</Text>
      <Button onClick={() => navigate(-2)}>Back</Button>
    </Stack>
  )
}

export default NoAccessPage